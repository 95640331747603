<template>

  <div ref="artRef" class="artplayer-app"></div>

</template>

<script>
import Artplayer from 'artplayer';
import Bus from "@/utils/bus";
import {apiRequest} from "@/api/api";
import store from "@/store";
import getErrorcode from "@/api/common";
var player
export default {
  name: "ArtPlayer",
  props: {
    playDialogVideoVisible: {
      type: Boolean,
      required: false,
    },
    videoUrl: {
      type: String,
      required: '',
    },
  },
  watch:{
    playDialogVideoVisible:{
      handler(val,old){
        if(!val){
          player.pause()
        }
      }
    }
  },
  mounted() {

     player = new Artplayer({
      //...this.option,
     // container: this.$refs.artRef,
      container: '.artplayer-app',
      url: this.videoUrl,
      title: 'Your Name',
     // poster: '/assets/sample/poster.jpg',
      volume: 0.5,
      isLive: false,
      muted: false,
      autoplay: true,
      pip: true,
      autoSize: true,
      autoMini: true,
      screenshot: true,
      setting: true,
      loop: true,
      flip: true,
      playbackRate: true,
      aspectRatio: true,
      fullscreen: true,
      fullscreenWeb: true,
      subtitleOffset: false,
      miniProgressBar: true,
      mutex: true,
      hotkey:true,
      backdrop: true,
      playsInline: true,
      autoPlayback: true,
      airplay: true,
      theme: '#23ade5',
      lang: 'zh-cn',
      whitelist: ['*'],
      moreVideoAttr: {
        crossOrigin: 'anonymous',
      },
      icons: {
        loading: '<img src="https://static.qiankun6.com/cloud/2023/321/ploading.gif">',
        state: '<img width="150" heigth="150" src="https://static.qiankun6.com/cloud/2023/321/state.svg">',
        indicator: '<img width="16" heigth="16" src="https://static.qiankun6.com/cloud/2023/321/indicator.svg">',
      },

    });
    player.on('video:ended', () => {
      console.log("播放完成");
      if(this.$store.state.task.index===1&&this.$store.state.task.is_finish===0){
       // this.completeCourseTask()
      }
    });
    document.querySelector(".artplayer-app").oncontextmenu =  () => {
      document.querySelector(".art-contextmenu-info").style.display = "none";
      document.querySelector(".art-contextmenu-version").style.display = "none";
      return false;
    };
  },
  methods:{
    completeCourseTask(){
      let params = ({
        "request_type": "course/complete_course_task","index":1
      });
      apiRequest(params).then((res) => {
        if (res.code == "SUCCESS") {
          //console.log("completeCourseTask",res)
          Bus.$emit('video_end', true);
        } else {
          this.$message.error(getErrorcode(res.code));
        }
      })
    }
  }

}
</script>

<style>
.artplayer-app {
  width: 100%;
  height: 100%;
}
</style>