import Vue from 'vue';
import Vuex from 'vuex';
import {apiRequest} from "@/api/api";
import {Message} from "element-ui";
import getErrorcode from "@/api/common";
import store from "@/store";
import {getBtih} from "@/utils/formatCheck";
import {getUserStatus} from "@/utils/getUserStatus";
import Bus from "@/utils/bus";
import OSS from "ali-oss";
Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        user_expire_seconds:0,//试用时间
        user: {
            phone: '',
            nickname:'',
            user_status_btn_tips: '',
            user_tag: '',
            space_used: '',
            space_size: '',
            avatar: '',
            user_tags:[],
            su:'',
        },
        upload: {
            fileList: []
        },
        socket:{
            status:'close'//open
        },
        task:{}
    },
    getters: {
        getSU:state => state.user.su,
        getNickName:state => state.user.nickname,
        getPhone: state => state.user.phone,
        getUserStatusTips: state => state.user.user_status_btn_tips,
        getUserTag: state => state.user.user_tag,
        getSpaceUsed: state => state.user.space_used,
        getSpaceSize: state => state.user.space_size,
        getAvatar: state => state.user.avatar,
        getSocketStatus:state=> state.socket.status,
        getUserTags:state=> state.socket.user_tags
    },
    mutations: {
        updateUserExpireSeconds(state, status){
            state.user_expire_seconds=status
        },
        updateTask(state,task){
            state.task=task
        },
        updateSocketStatus(state, status){
            state.socket.status = status
        },
        updateUserStatus(state, res) {
            state.user.su=res.su
            state.user.nickname=res.nickname
            state.user.phone = res.phone
            state.user.user_status_btn_tips = res.user_status_btn_tips
            state.user.user_tag = res.user_tag
            state.user.space_used = res.space_used
            state.user.space_size = res.space_size
            state.user.avatar = res.avatar
            state.user.user_tags=res.user_tags
        },
        addUploadFiles(state, obj) {
            state.upload.fileList.push(obj)
           // console.log("state.upload.fileList.mutations", state.upload.fileList.length)
        },
        updateUploadFiles(state, status){
            if(state.upload.fileList.length>0){
                state.upload.fileList[0].status=status
            }
        },
        shiftUploadFiles(state){
            if(state.upload.fileList.length>0){
                state.upload.fileList.shift()
            }
        },
        updateUploadFilesProgress(state, progress){
            if(state.upload.fileList.length>0){
                state.upload.fileList[0].progress=progress
            }
        },
    },
    actions: {
        uploadFiles(state, fileObj) {
            window.wcs.getEtagHash(fileObj.file, (file_etag) => {
                let obj = {};
                obj.file_name = fileObj.file.name
                obj.file_size = fileObj.file.size
                obj.file_etag = file_etag
                const split = fileObj.file.name.split('.')
                obj.suffix = split[split.length - 1]
                obj.file = fileObj.file
                obj.progress=0
                obj.path=fileObj.path
                obj.status = 'pending'//pending uploading success error
                state.commit('addUploadFiles', obj)
                dispach(state,obj)
            })
        },

    }
})

//分发任务
function dispach(state,obj) {
    if (store.state.upload.fileList.length > 0) {
        if(store.state.upload.fileList[0].status==='pending'){
            state.commit('updateUploadFiles', 'uploading')
            //uploadBaidu(state,obj)
            get_sts_session_token_oss(state,obj)
        }
    }
}


function uploadBaidu(state,obj) {//__file, file_etag, file_size, file_ext
    var params = ({
        "request_type": "global/get_sts_session_token",
        "file_etag": obj.file_etag, "file_size": obj.file_size, "file_ext": obj.suffix
    });
    apiRequest(params).then((res) => {
        if (res.code === "SUCCESS") {
            const {bucketName, credentials, endpoint, keyObject, protocol} = res
            const bosConfig = {
                credentials: {
                    ak: credentials.accessKeyId,
                    sk: credentials.secretAccessKey
                },
                sessionToken: credentials.sessionToken,
                endpoint: `${protocol}://${endpoint}`
            }
            const client = new window.baidubce.sdk.BosClient(bosConfig)
            const options = {
                'Content-Type': obj.file.type // 添加http header
            }
            const putObjectDone = (response) => {

                addFile(state,obj.file_name,obj.file_etag,obj.file_size,obj.path)
               // dispach(state,store.state.upload.fileList[0])
            }
            const putObjectFail = (fail) => {
                state.commit('updateUploadFiles', 'error')
            }
            client.putObjectFromBlob(bucketName, keyObject, obj.file, options)
                .then(putObjectDone)
                .catch(putObjectFail)
            client.on('progress', progress => {
                const {loaded, total} = progress
                const percentage = (loaded / total) * 100
                state.commit('updateUploadFilesProgress', percentage)
            })

        } else {
            Message.error(getErrorcode(res.code));
        }
    })
}
function get_sts_session_token_oss(state,obj){
    var params = ({
        "request_type": "global/get_sts_session_token",
        "file_etag": obj.file_etag, "file_size": obj.file_size, "file_ext": obj.suffix
    });
    apiRequest(params).then(async (res) => {
        let v=this;
        console.log("get_sts_session_token_oss"+res.endpoint)
        var OSS = require('ali-oss');
        const client = new OSS({
            // yourRegion填写Bucket所在地域。以华东1（杭州）为例，yourRegion填写为oss-cn-hangzhou。
            region: res.region,
            // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
            accessKeyId: res.sts.accessKeyId,
            accessKeySecret: res.sts.accessKeySecret,
            // 从STS服务获取的安全令牌（SecurityToken）。
            stsToken: res.sts.securityToken,
            // 填写Bucket名称，例如examplebucket。
            bucket: res.bucket,
            refreshSTSTokenInterval: 3600 * 1000, // 刷新间隔，单位是毫秒
        });
        state.commit('updateUploadFilesProgress', 0)
        let data=obj.file;
        try {
            client.put(res.keyObject, data, {
                progress: function (p) {
                    console.log('Progress: ' + "222222222"+p);
                }
            }).then(function (s) {
                console.log('updateUploadSuccess: ' + s);
                addFile(state,obj.file_name,obj.file_etag,obj.file_size)
                state.commit('updateUploadFilesProgress', 100)
            }).catch(function (err) {
                console.log('updateUploadFiles: ' + err);
                state.commit('updateUploadFiles', 'error')
                console.log('Error: ', err);
            });
        } catch (e) {
            console.log(e);
        }

    })
}








//上传图片完毕后添加到文件列表
function addFile(state,filename,file_etag,file_size){
    let params = ({"request_type": "cloud/add_file", "filename":filename,
        "file_etag":file_etag,"file_size":file_size,"file_time":new Date().getTime(),"pfid":0});
    apiRequest(params).then((res) => {
        Bus.$emit('updatefiles', true);
        state.commit('shiftUploadFiles')
        dispach(state,store.state.upload.fileList[0])
        /*if (res.code === "SUCCESS") {
            Bus.$emit('updatefiles', true);
            state.commit('shiftUploadFiles')
            dispach(state,store.state.upload.fileList[0])
        } else if (res.code === "E_INSUFFICIENT_SPACE") {
            Message.error("所需空间不足！");
            Bus.$emit('updatefiles_limit', res);
        } else {
            Message.error(getErrorcode(res.code));
        }*/
    })
}
