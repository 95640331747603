<template>
  <el-aside :width="isCollapse ? '65px' : '200px'" style="background: #FFFFFF;
border-style:solid;border-right:solid #f0f0f0;border-width:0px 1px 0px 0px">
    <logo :is-collapse="isCollapse"></logo>
    <!-- menu -->
    <el-scrollbar>
      <el-menu :default-active="($route.path==='/browser/browser'||$route.path==='/browser/index'||$route.path==='/browser/more')
      ?'/browser':$route.path"
               text-color="#333333"
               style="border-right: none;margin-top: 60px"
               :collapse-transition="false"
               :unique-opened="true"
               :collapse="isCollapse"
               :router="true"
      >
        <SidebarItem v-for="route in permissionRouters"
                     :key="route.name"
                     :item="route" :base-path="route.path"></SidebarItem>
      </el-menu>
      <div class="space-box">
        <div style="font-size: 13px;color:#999999;margin-left: 20px;">昵称：{{ $store.state.user.nickname }}</div>
        <div class="user_size_box">
          <div class="user_size_box_text1">已用</div>
          <div class="user_size_box_text2">{{
              $store.state.user.space_used |spaceFilter
            }}/{{ $store.state.user.space_size|spaceFilter }}
          </div>
        </div>
        <div v-show="!isCheckCustomer" class="user_size_box_text1" style="margin-bottom: 20px;color: #3079E7;
        text-decoration: underline;cursor: pointer;" ref="btn" :data-clipboard-text="textToCopy">复制登录秘钥</div>
        <div style="display: flex;justify-items: center">
          <el-progress v-if='$store.state.user.space_size!=0' style="width:63%; margin-left: 20px" :show-text="false"
                       :stroke-width="5"
                       :percentage="($store.state.user.space_used/$store.state.user.space_size)*100"></el-progress>
          <span style="font-size: 12px;margin-top: -6px;margin-left: 10px;color: #3079E7;cursor: pointer;"
                @click="toVipCenter">扩容</span>
        </div>
      </div>
    </el-scrollbar>
    <!-- menu -->

  </el-aside>

</template>

<script>
import {mainRoutes} from '../router';
import SidebarItem from './SidebarItem.vue';
import Logo from './Logo'
import spaceFilter from "@/utils/spaceFilter";
import ClipboardJS from 'clipboard'
import {storage} from "@/utils/storage";
import { Message } from 'element-ui';
import { checkCustomer } from '@/utils/mock';  // 替换成你的模块路径
export default {
  props: ['isCollapse'],
  data() {
    return {
      asideWidth: '200px',
      textToCopy:"",
      isCheckCustomer:false
      // permission_routers: routes,
    };
  },
  components: {
    SidebarItem, Logo
  },
  filters: {
    spaceFilter(space_size, toFixed) {
      if (!space_size || space_size === '0B') {
        return '0kb'
      }
      return spaceFilter(space_size, toFixed)
    },
  },
  methods: {

    toVipCenter() {
      this.$router.push({name: 'usercneter', params: {checked: 'space'}})
    },
    copy(){
      const textToCopy = '这是需要复制的内容';
      navigator.clipboard.writeText(textToCopy)
          .then(() => {
            alert('复制成功!');
          })
          .catch(err => {
            console.error('复制失败: ', err);
          });
    }
  },
  computed: {
    permissionRouters() {
      return mainRoutes;
    },
  },mounted() {
    if(checkCustomer()){
      this.isCheckCustomer=true
    }else {
      this.isCheckCustomer=false
    }

    const clipboard= new ClipboardJS(this.$refs.btn);
    clipboard.on('success', function(e) {
      Message.success("秘钥复制成功");
      e.clearSelection();
    });

    clipboard.on('error', function(e) {
      Message.error("秘钥复制失败");
    });
    this.textToCopy=storage.getSK()
  }
};
</script>
<style lang="less">

.el-aside > .el-scrollbar {
  height: 100%;

  .el-scrollbar__wrap {
    overflow-x: auto;
  }
}
</style>
<style lang="scss" scoped>
.space-box {
  position: fixed;
  bottom: 40px;
}

.user_size_box_text1 {
  font-size: 12px;
  color: #999999;
  width: auto;
  margin-left: 20px;
}

.user_size_box_text2 {
  font-size: 12px;
  color: #999999;
  margin-right: 20px;
}

.user_size_box {
  display: flex;
  justify-content: space-between;
  width: 200px;
  height: 40px;
  text-align: center;
  align-items: center;
  //margin-top: 30px;
}


</style>

