<template>
  <div class="aside-logo">
    <div class="title_sub"
      v-if="!isCollapse">
      <el-image class="title_sub" mode='scaleToFill'
                src="https://static.qiankun6.com/cloud/2024/124/20240124-155956.png"/>
    </div>

    <div class="title_sub_mini"
      v-else><b>CILIZHAI</b></div>
  </div>
</template>

<script>
  export default {
    props: {
      isCollapse: {
        type: Boolean,
        required: true,
      },
    },
    data() {
      return {};
    },
  };
</script>
<style lang="less" scoped>
  .el-aside > .aside-logo {
   // background: #367fa9;
    background: #ffffff;
    position: fixed;
    height: 40px;
    z-index: 999;
    padding: 10px;
    width: 100%;
    // box-shadow: 1px 4px 5px rgba(85, 85, 85, 0.74);
  }
  .title_main {
    color: #fff;
    font-weight: bold;
    line-height: 40px;
  }
  .title_sub {
    width: 120px;
    color: #ddd;
    font-size: 16px;
    line-height: 40px;
    text-align: center;
    margin-left: 5px;
  }

  .title_sub_mini {
    width: 64px;
    color: #ddd;
    font-size: 12px;
    line-height: 40px;
  }
</style>

