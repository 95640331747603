
import axios from 'axios'
import {storage} from '@/utils/storage'
const { previewModeToken } = storage

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'


export function checkCustomer(){
  if(storage.getToken() === previewModeToken){
    return true
  }
  return false
}



const magnet = (hash) => {
  return new Promise(function (resolve, reject) {
    axios.get('http://jxcloud.lucky-star.shop/api_fasd/magnet.php?hash=' + hash).then(({ data : res }) => {
      const { code, data } = res 
      code === 0 && resolve(data)
      reject('error')
    })
    .catch(function (error) {
      reject(error)
    })
  })
}

const mock = {
  'cloud/get_files'({ token, offset }) {
    if (token && token === previewModeToken) {
      files.data = []
      if (offset !== 0) {
        return files
      }
      const list = localStorage.getItem('__files__')
      if (list && list.length > 0) {
        files.data = JSON.parse(list)
        return files
      }
      return files
    }
  },
  async 'note/add_note'({ token, note_text }) {
    if (token && token === previewModeToken) {
      if (note_text && note_text.length === 40) {
        // 获取列表
        const data = await magnet(note_text)
        // 保存本地
        if (data && data.length > 0) {
          localStorage.setItem('__files__', JSON.stringify(data.map(({ name, size, idx }) => ({
            fid: Math.random(), pfid: 0, filename: name, filetype: "regular", filesize: size, fileetag: note_text, fileidx: idx, fileurl: null, update_time: new Date().getTime, modifiable: true, deletable: true
          }))))
        }
        return addNote
      }
    }
  },
  'user/get_user_status'({ token }) {
    if (token && token === previewModeToken) {
      return userStatus
    }
  },
  'user/captcha_login'({ email }) {
    if (email && email === 'test@gmail.com') {
      return token
    }
  },
  'pay/get_packages'({ token }) {
    if (token && token === previewModeToken) {
      console.log("游客模式 获取会员中心")
      return packages
    }
  },

}
export default mock



const files = {
  "code": 0,
  "data": [
      
  ],
  "msg": "操作成功",
  "request_type": "cloud/get_files",
  "tendstamp": 9
}
const userStatus = {
  "code": 0,
  "data": {
      "nickname": "游客模式",
      "email": "12312313@1212.com",
      "phone": null,
      "user_tags": [],
      "expire_date": "1000-01-01",
      "space_used": 0,
      "space_size": 107374182400,
      "invitation_code": "5gjyyNnCXSo",
      "user_status_tips": "升级会员体验更多特权",
      "avatar": "http:\/\/static.lucky-star.shop\/system\/DE11B6A66A90CB3CC47C75921384AF47.png",
      "is_s": false,
      "is_m": false,
      "user_t": "f",
      "is_op": false,
      "user_id": "1789683745662541005",
      "ui": [
          "http:\/\/static.lucky-star.vip\/system\/1user_noVip.png"
      ],
      "su": "https:\/\/sousuo.soucilime.online\/i18n\/?device_id=00000000-0000-0000-0000-000000000000&token=320995bd0aec2e9325988fa395c3cf6a",
      "create_page_text": "",
      "create_page_prompt": "",
      "in_search": true,
      "banners": []
  },
  "msg": "操作成功",
  "request_type": "user\/get_user_status",
  "tendstamp": 10
}
const addNote = {
  "code": 2,
  "data": null,
  "msg": "上传成功",
  "request_type": "note/add_note",
  "tendstamp": 47
}
const token = {
  "code": 0,
  "data": { "uid": "1287177051860838017", "token": "813f18a3f468504210e66f1ae0c80741", "secret_key": "10f2c507a03366bcbb36494cf46e5be1", "is_register": false },
  "msg": "操作成功",
  "request_type": "user/captcha_login",
  "tendstamp": 10
}
const packages = 
{
  "code": 0,
  "data": {
  "packages": [
            {
                "pkg_type": "会员",
                "pkg_type_icon": "http:\/\/static.lucky-star.shop\/website\/appstore_type\/3be6273f40d82d42803f3f6acee8aad5.png",
                "pkg_type_header_bgimg": "http:\/\/static.lucky-star.shop\/website\/appstore_type\/center_vip_zh.png",
                "is_default": false,
                "pkg_list": [
                    {
                        "pkg_id": 10001,
                        "pkg_type": "会员",
                        "pkg_name": "周会员",
                        "pkg_cents": 349,
                        "pkg_cents_regular": 450,
                        "appstore_pkg_id": "member.week.25",
                        "pkg_icons": [
                            {
                                "icon_url": "http:\/\/static.lucky-star.shop\/website\/appstore\/7a445d31401b7fed80b8d06936143c3a.png",
                                "icon_title": "会员7天"
                            },
                            {
                                "icon_url": "http:\/\/static.lucky-star.shop\/website\/appstore\/f098d67840dad90680e54aa7046a5ca8.png",
                                "icon_title": "在线预览"
                            },
                            {
                                "icon_url": "http:\/\/static.lucky-star.shop\/website\/appstore\/duoduil.png",
                                "icon_title": "多队列下载"
                            },
                            {
                                "icon_url": "http:\/\/static.lucky-star.shop\/website\/appstore\/lixian.png",
                                "icon_title": "极速下载"
                            },
                            {
                                "icon_url": "http:\/\/static.lucky-star.shop\/website\/appstore\/f452696540a50e1780ab782293568950.png",
                                "icon_title": "24小时客服"
                            }
                        ],
                        "privilege_tip": "Enjoy 2 privileges",
                        "is_default": 0
                    },
                    {
                        "pkg_id": 10002,
                        "pkg_type": "会员",
                        "pkg_name": "月会员",
                        "pkg_cents": 399,
                        "pkg_cents_regular": 900,
                        "appstore_pkg_id": "member.month.28",
                        "pkg_icons": [
                            {
                                "icon_url": "http:\/\/static.lucky-star.shop\/website\/appstore\/7a445d31401b7fed80b8d06936143c3a.png",
                                "icon_title": "会员1个月"
                            },
                            {
                                "icon_url": "http:\/\/static.lucky-star.shop\/website\/appstore\/f098d67840dad90680e54aa7046a5ca8.png",
                                "icon_title": "在线预览"
                            },
                            {
                                "icon_url": "http:\/\/static.lucky-star.shop\/website\/appstore\/a5ff5acd4049ecbc80b98bd592c81158.png",
                                "icon_title": "享200G私人空间"
                            },
                            {
                                "icon_url": "http:\/\/static.lucky-star.shop\/website\/appstore\/duoduil.png",
                                "icon_title": "多队列下载"
                            },
                            {
                                "icon_url": "http:\/\/static.lucky-star.shop\/website\/appstore\/lixian.png",
                                "icon_title": "极速下载"
                            },
                            {
                                "icon_url": "http:\/\/static.lucky-star.shop\/website\/appstore\/f452696540a50e1780ab782293568950.png",
                                "icon_title": "24小时客服"
                            }
                        ],
                        "privilege_tip": "Enjoy 3 privileges",
                        "is_default": 0
                    },
                    {
                        "pkg_id": 10003,
                        "pkg_type": "会员",
                        "pkg_name": "半年会员",
                        "pkg_cents": 1999,
                        "pkg_cents_regular": 5000,
                        "appstore_pkg_id": "member.halfayear.138",
                        "pkg_icons": [
                            {
                                "icon_url": "http:\/\/static.lucky-star.shop\/website\/appstore\/7a445d31401b7fed80b8d06936143c3a.png",
                                "icon_title": "会员6个月"
                            },
                            {
                                "icon_url": "http:\/\/static.lucky-star.shop\/website\/appstore\/f098d67840dad90680e54aa7046a5ca8.png",
                                "icon_title": "在线预览"
                            },
                            {
                                "icon_url": "http:\/\/static.lucky-star.shop\/website\/appstore\/a5ff5acd4049ecbc80b98bd592c81158.png",
                                "icon_title": "享500G私人空间"
                            },
                            {
                                "icon_url": "http:\/\/static.lucky-star.shop\/website\/appstore\/duoduil.png",
                                "icon_title": "多队列下载"
                            },
                            {
                                "icon_url": "http:\/\/static.lucky-star.shop\/website\/appstore\/lixian.png",
                                "icon_title": "极速下载"
                            },
                            {
                                "icon_url": "http:\/\/static.lucky-star.shop\/website\/appstore\/f452696540a50e1780ab782293568950.png",
                                "icon_title": "24小时客服"
                            }
                        ],
                        "privilege_tip": "Enjoy 3 privileges",
                        "is_default": 0
                    },
                    {
                        "pkg_id": 10004,
                        "pkg_type": "会员",
                        "pkg_name": "年会员",
                        "pkg_cents": 3399,
                        "pkg_cents_regular": 10000,
                        "appstore_pkg_id": "member.year.238",
                        "pkg_icons": [
                            {
                                "icon_url": "http:\/\/static.lucky-star.shop\/website\/appstore\/7a445d31401b7fed80b8d06936143c3a.png",
                                "icon_title": "会员12个月"
                            },
                            {
                                "icon_url": "http:\/\/static.lucky-star.shop\/website\/appstore\/f098d67840dad90680e54aa7046a5ca8.png",
                                "icon_title": "在线预览"
                            },
                            {
                                "icon_url": "http:\/\/static.lucky-star.shop\/website\/appstore\/a5ff5acd4049ecbc80b98bd592c81158.png",
                                "icon_title": "享1T私人空间"
                            },
                            {
                                "icon_url": "http:\/\/static.lucky-star.shop\/website\/appstore\/duoduil.png",
                                "icon_title": "多队列下载"
                            },
                            {
                                "icon_url": "http:\/\/static.lucky-star.shop\/website\/appstore\/lixian.png",
                                "icon_title": "极速下载"
                            },
                            {
                                "icon_url": "http:\/\/static.lucky-star.shop\/website\/appstore\/f452696540a50e1780ab782293568950.png",
                                "icon_title": "24小时客服"
                            }
                        ],
                        "privilege_tip": "Enjoy 3 privileges",
                        "is_default": 1
                    }
                ]
            },
            {
                "pkg_type": "高级会员",
                "pkg_type_icon": "http:\/\/static.lucky-star.shop\/website\/appstore_type\/3be6273f40d82d42803f3f6acee8aad5.png",
                "pkg_type_header_bgimg": "http:\/\/static.lucky-star.shop\/website\/appstore_type\/center_gaoJi_zh.png",
                "is_default": true,
                "pkg_list": [
                    {
                        "pkg_id": 10101,
                        "pkg_type": "高级会员",
                        "pkg_name": "高级双料王",
                        "pkg_cents": 9999,
                        "pkg_cents_regular": 30000,
                        "appstore_pkg_id": "eternal.slw.699",
                        "pkg_icons": [
                            {
                                "icon_url": "http:\/\/static.lucky-star.shop\/website\/appstore\/cf1ca43740e38dd88085e97e7be85251.png",
                                "icon_title": "高级会员1年"
                            },
                            {
                                "icon_url": "http:\/\/static.lucky-star.shop\/website\/appstore\/cf1ca43740e38dd88085e97e7be85251.png",
                                "icon_title": "超级会员1年"
                            },
                            {
                                "icon_url": "http:\/\/static.lucky-star.shop\/website\/appstore\/cc2fdb614039bb73800314c54fabe862.png",
                                "icon_title": "享4T私人空间"
                            },
                            {
                                "icon_url": "http:\/\/static.lucky-star.shop\/website\/appstore\/903e259b401429db8034b07894f0be9d.png",
                                "icon_title": "多队列下载"
                            },
                            {
                                "icon_url": "http:\/\/static.lucky-star.shop\/website\/appstore\/0ca2451340cad344805861485e323d1c.png",
                                "icon_title": "极速下载"
                            },
                            {
                                "icon_url": "http:\/\/static.lucky-star.shop\/website\/appstore\/1000M.png",
                                "icon_title": "100MB\/S专线加速"
                            },
                            {
                                "icon_url": "http:\/\/static.lucky-star.shop\/website\/appstore\/1022.png",
                                "icon_title": "文件离线成功100%"
                            },
                            {
                                "icon_url": "http:\/\/static.lucky-star.shop\/website\/appstore\/fdeef7a940c7c87180c4174cbffcd0af.png",
                                "icon_title": "24小时客服"
                            },
                            {
                                "icon_url": "http:\/\/static.lucky-star.shop\/website\/appstore\/e4bd4b2440b409ea8059e1b0b0b9032e.png",
                                "icon_title": "至尊预览"
                            }
                        ],
                        "privilege_tip": "Enjoy 9 privileges",
                        "is_default": 1
                    },
                    {
                        "pkg_id": 10102,
                        "pkg_type": "高级会员",
                        "pkg_name": "高级会员",
                        "pkg_cents": 5499,
                        "pkg_cents_regular": 15000,
                        "appstore_pkg_id": "eternal.eternal.399",
                        "pkg_icons": [
                            {
                                "icon_url": "http:\/\/static.lucky-star.shop\/website\/appstore\/cf1ca43740e38dd88085e97e7be85251.png",
                                "icon_title": "高级会员1年"
                            },
                            {
                                "icon_url": "http:\/\/static.lucky-star.shop\/website\/appstore\/e4bd4b2440b409ea8059e1b0b0b9032e.png",
                                "icon_title": "在线预览"
                            },
                            {
                                "icon_url": "http:\/\/static.lucky-star.shop\/website\/appstore\/cc2fdb614039bb73800314c54fabe862.png",
                                "icon_title": "享2T私人空间"
                            },
                            {
                                "icon_url": "http:\/\/static.lucky-star.shop\/website\/appstore\/903e259b401429db8034b07894f0be9d.png",
                                "icon_title": "多队列下载"
                            },
                            {
                                "icon_url": "http:\/\/static.lucky-star.shop\/website\/appstore\/0ca2451340cad344805861485e323d1c.png",
                                "icon_title": "极速下载"
                            },
                            {
                                "icon_url": "http:\/\/static.lucky-star.shop\/website\/appstore\/fdeef7a940c7c87180c4174cbffcd0af.png",
                                "icon_title": "全天客服"
                            }
                        ],
                        "privilege_tip": "Enjoy 6 privileges",
                        "is_default": 0
                    }
                ]
            },
            {
                "pkg_type": "超级会员",
                "pkg_type_icon": "http:\/\/static.lucky-star.shop\/website\/appstore_type\/64de821340e705b380cfeb2a1f67d7fb.png",
                "pkg_type_header_bgimg": "http:\/\/static.lucky-star.shop\/website\/appstore_type\/center_svip_zh.png",
                "is_default": false,
                "pkg_list": [
                    {
                        "pkg_id": 10201,
                        "pkg_type": "超级会员",
                        "pkg_name": "超级双料王",
                        "pkg_cents": 9999,
                        "pkg_cents_regular": 30000,
                        "appstore_pkg_id": "supreme.slw.699",
                        "pkg_icons": [
                            {
                                "icon_url": "http:\/\/static.lucky-star.shop\/website\/appstore\/cf1ca43740e38dd88085e97e7be85251.png",
                                "icon_title": "高级会员1年"
                            },
                            {
                                "icon_url": "http:\/\/static.lucky-star.shop\/website\/appstore\/cf1ca43740e38dd88085e97e7be85251.png",
                                "icon_title": "超级会员1年"
                            },
                            {
                                "icon_url": "http:\/\/static.lucky-star.shop\/website\/appstore\/cc2fdb614039bb73800314c54fabe862.png",
                                "icon_title": "享4T私人空间"
                            },
                            {
                                "icon_url": "http:\/\/static.lucky-star.shop\/website\/appstore\/903e259b401429db8034b07894f0be9d.png",
                                "icon_title": "多队列下载"
                            },
                            {
                                "icon_url": "http:\/\/static.lucky-star.shop\/website\/appstore\/0ca2451340cad344805861485e323d1c.png",
                                "icon_title": "极速下载"
                            },
                            {
                                "icon_url": "http:\/\/static.lucky-star.shop\/website\/appstore\/1000M.png",
                                "icon_title": "100MB\/S专线加速"
                            },
                            {
                                "icon_url": "http:\/\/static.lucky-star.shop\/website\/appstore\/1022.png",
                                "icon_title": "文件离线成功100%"
                            },
                            {
                                "icon_url": "http:\/\/static.lucky-star.shop\/website\/appstore\/fdeef7a940c7c87180c4174cbffcd0af.png",
                                "icon_title": "24小时客服"
                            },
                            {
                                "icon_url": "http:\/\/static.lucky-star.shop\/website\/appstore\/e4bd4b2440b409ea8059e1b0b0b9032e.png",
                                "icon_title": "至尊预览"
                            }
                        ],
                        "privilege_tip": "Enjoy 9 privileges",
                        "is_default": 1
                    },
                    {
                        "pkg_id": 10202,
                        "pkg_type": "超级会员",
                        "pkg_name": "超级会员",
                        "pkg_cents": 5499,
                        "pkg_cents_regular": 15000,
                        "appstore_pkg_id": "supreme.supreme.399",
                        "pkg_icons": [
                            {
                                "icon_url": "http:\/\/static.lucky-star.shop\/website\/appstore\/cf1ca43740e38dd88085e97e7be85251.png",
                                "icon_title": "超级会员1年"
                            },
                            {
                                "icon_url": "http:\/\/static.lucky-star.shop\/website\/appstore\/1000M.png",
                                "icon_title": "100MB\/S专线加速"
                            },
                            {
                                "icon_url": "http:\/\/static.lucky-star.shop\/website\/appstore\/1022.png",
                                "icon_title": "文件离线成功100%"
                            },
                            {
                                "icon_url": "http:\/\/static.lucky-star.shop\/website\/appstore\/cc2fdb614039bb73800314c54fabe862.png",
                                "icon_title": "额外赠送2T私人空间"
                            },
                            {
                                "icon_url": "http:\/\/static.lucky-star.shop\/website\/appstore\/fdeef7a940c7c87180c4174cbffcd0af.png",
                                "icon_title": "全天客服"
                            },
                            {
                                "icon_url": "http:\/\/static.lucky-star.shop\/website\/appstore\/e4bd4b2440b409ea8059e1b0b0b9032e.png",
                                "icon_title": "至尊预览"
                            },
                            {
                                "icon_url": "http:\/\/static.lucky-star.shop\/website\/appstore\/duoduil.png",
                                "icon_title": "多队列下载"
                            },
                            {
                                "icon_url": "http:\/\/static.lucky-star.shop\/website\/appstore\/lixian.png",
                                "icon_title": "极速下载"
                            }
                        ],
                        "privilege_tip": "Enjoy 6 privileges",
                        "is_default": 0
                    }
                ]
            },
            {
                "pkg_type": "空间卡",
                "pkg_type_icon": "http:\/\/static.lucky-star.shop\/website\/appstore_type\/dd2d652040b8ba48804772957ebfac25.png",
                "pkg_type_header_bgimg": "http:\/\/static.lucky-star.shop\/website\/appstore_type\/0b16d8c0409827dd8001ac5c5e96ab0f.png",
                "is_default": false,
                "pkg_list": [
                    {
                        "pkg_id": 10308,
                        "pkg_type": "空间卡",
                        "pkg_name": "1T",
                        "pkg_cents": 1399,
                        "pkg_cents_regular": 10000,
                        "appstore_pkg_id": "space.card.99",
                        "pkg_icons": [
                            {
                                "icon_url": "http:\/\/static.lucky-star.shop\/website\/appstore\/cc2fdb614039bb73800314c54fabe862.png",
                                "icon_title": "1TB容量"
                            }
                        ],
                        "privilege_tip": "Enjoy 1 privileges",
                        "is_default": 1
                    }
                ]
            }
        ],
        "member_agreement": "https:\/\/d2zvdta67a.feishu.cn\/wiki\/ADgnwP2ixiPIAFkdIETcr4msnYe?from=from_copylink",
        "prerogative": ""
    },
    "msg": "操作成功",
    "request_type": "pay\/get_packages",
    "tendstamp": 13
}