/**
 * 封装操作localstorage本地存储的方法
 */
export const storage = {
    // 存储
    set(key, value) {
        localStorage.setItem(key, JSON.stringify(value))
    },
    // 取出
    get(key) {
        const value = localStorage.getItem(key)
        if (value && value !== 'undefined' && value !== 'null') {
            return JSON.parse(value)
        }
        return ""
    },
    // 删除
    remove(key) {
        localStorage.removeItem(key)
    },

    setToken(value){
        this.set('token',value)
    },
    getToken(){
        const token = this.get('token')
        if (token) {
            return token
        }
        return this.previewModeToken
    },
    setUnSeeVideo(value){
        this.set('has_see',value)
    },
    getUnSeeVideo(){
        return this.get('has_see')
    },
    setUid(value){
        this.set('my_uid',value)
    },
    getUid(){
        return this.get('my_uid')
    },
    setSK(value){
        this.set('my_sk',value)
    },
    getSK(){
        return this.get('my_sk')
    },
    setWebsite(value){
        this.set('my_website',value)
    },
    getWebsite(){
        return this.get('my_website')
    },
    setUploadNum(value){
        localStorage.setItem('myInt', value);
    },
    getUploadNum(){
        var retrievedIntValue = localStorage.getItem('myInt');
        var intValue = retrievedIntValue ? parseInt(retrievedIntValue) : 0;
        return intValue
    },

    previewModeToken: '813f18a3f468504210e66f1ae0c80741'
}

/**
 * 封装操作sessionStorage本地存储的方法
 */
export const session = {
    // 存储
    set(key, value) {
        window.sessionStorage.setItem(key, JSON.stringify(value))
    },
    // 取出
    get(key) {
        const value = window.sessionStorage.getItem(key)
        if (value && value !== 'undefined' && value !== 'null') {
            return JSON.parse(value)
        }
        return null
    },
    // 删除
    remove(key) {
        window.sessionStorage.removeItem(key)
    }
}

