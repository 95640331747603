import axios from 'axios';
import {storage} from '../utils/storage.js'
import routes from "@/router";
import { Message } from 'element-ui';
import getErrorcode from "@/api/common";
import mock from "@/utils/mock";
import { checkCustomer } from '@/utils/mock';  

var apiList=["user/captcha_login","sms/send_captcha_login","user/secret_login","user/visitor_login"]
export function apiRequest(data = {}) {
    var params = ({
        "timestamp": Date.parse(new Date()) / 1000,
        "os_type": "h5",
        "app_name": "jxcloud",
        "app_version": "1.0",
        "channel": "webstore",
        "token":apiList.includes(data.request_type)?"":storage.getToken(),
        "lang":"zh"
    });
    var _mock_ = Object.assign(params, data);
    var json = JSON.stringify(Object.assign(params, data));//JSON.stringify() 方法用于将 JavaScript 值转换为 JSON 字符串
    var contentType = 'application/x-www-form-urlencoded'
    return new Promise(function (resolve, reject) {
        if (_mock_ && typeof _mock_.request_type === 'string' && mock && typeof mock[_mock_.request_type] === 'function' && checkCustomer()) {
            const ret_mock = mock[_mock_.request_type](_mock_);
            if (ret_mock) {
                console.log("游客模式拦截")
                const { data } = ret_mock
                console.log(data)
                return resolve(data);
            }
        }

        axios.defaults.headers.post['Content-Type'] = contentType;//+params.request_type
        axios.post('http://jxcloud.lucky-star.vip/mini_api/',json)
            .then(function (res) {
                console.log("res+"+res.data.code)
                if(res.data.code===0){
                    resolve(res.data.data);
                } else if (res.data.code === 1) {
                    Message.error(res.data.msg);
                }else if (res.data.code === 2) {
                    resolve(res.data.data);
                } else if (res.data.code >= 200 && res.data.code < 300) {
                    reject(res.data.data);
                }else if (res.data.code >= 300 && res.data.code < 400) {
                    Message.error(res.data.msg);
                    if (res.data.code === 304) {
                        storage.setToken('')
                        storage.setUid("")
                        storage.setSK("")
                        routes.push('/auth/login')
                    }
                }else if (res.data.code >= 500 && res.data.code < 600) {
                    Message.error(res.data.msg);
                }
            })
            .catch(function (error) {
                Message.error("服务器连接失败，请检查网络设置");
                reject(error);
            })
        ;
    });
}
