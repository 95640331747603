<template>
  <div class="mine_header_box">
    <div class="phone_box">
<!--      <el-badge value="hot" class="item">
        <el-button   size="small" icon="el-icon-download" round
                    type="primary" @click="downLoadQt">PC全能客户端下载
        </el-button>
      </el-badge>-->
      <a style="color: #2fa1ee;font-size: 12px;margin-right: 10px;margin-left: 30px;cursor:pointer" @click="openVideoDialog()">不会用？请点击这里</a>
      <span v-if="showDots" class="redDots"></span>
      <el-image style="width: 35px; height: 35px; " :src="$store.state.user.avatar"></el-image>
      <div style="margin-left: 10px">
<!--        <div style="display: flex;align-items: center;">
          <div class="phone_text_mine">{{ $store.state.user.phone }}</div>
        </div>-->
        <div v-if="$store.state.user.user_tags.length>0" style="display: flex;align-items: center" v-for="(item, index) in $store.state.user.user_tags">
          <div style="color: #999999;font-size: 12px" >{{item.expire_date}}</div>

<!--          <div style="color: #999999;font-size: 12px" v-if="item.probation===false">{{item.user_status_btn_tips}}</div>
          <div style="color: #999999;font-size: 12px" v-else>{{formateExpireSeconds()}}</div>-->

          <el-image style="width: 60px; height: auto;" :src="item.user_tag|userTagFilter">
          </el-image>
        </div>
        <div v-if="$store.state.user.user_tags.length===0" style="display: flex;align-items: center" >
          <div style="color: #999999;font-size: 12px" >欢迎使用magPie</div>
        </div>
      </div>
    </div>
    <el-dialog custom-class="customClass"  title="使用说明" v-if="playDialogVisibleVideo"
               :visible.sync="playDialogVisibleVideo" width="800px" :modal="false" :before-close="closeDialogVideo">
      <div style="text-align: center;width: auto;height: 400px">
        <ArtPlayer video-url="http://static.lucky-star.vip/video/20240130-164242.mp4"
                   :play-dialog-video-visible="playDialogVisibleVideo"></ArtPlayer>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import ArtPlayer from "@/components/ArtPlayer";
import {storage} from "@/utils/storage";
import {clickCourseTask} from "@/utils/commonApi";
import {apiRequest} from "@/api/api";
import getErrorcode from "@/api/common";

export default {
  name: "UserStatus",
  components: {ArtPlayer},
  data(){
    return{
      playDialogVisibleVideo:false,
      showDots:true
    }
  },
  methods:{
    downLoadQt() {
      //v-if="$store.state.user.phone.toString().slice(-1)==='5'"
      let params = ({"request_type": "global/client_url_15"});
      apiRequest(params).then((res) => {
        if (res.code === "SUCCESS") {
          if (this.deviceTypeIsIos()) {
            const w = window.open("about:blank");
            w.location.href = res.mac_client_url;
            this.$emit('v-handle-plug', false)
          } else {
            const w = window.open("about:blank");
            w.location.href = res.windows_client_url;
            this.$emit('v-handle-plug', false)
          }
        } else {
          this.$message.error(getErrorcode(res.code));
        }
      })
    },
    deviceTypeIsIos(){
      let useragent = navigator.userAgent;
      if ( useragent.match ( /iPhone|Safari|iPad|iPod|iOS/i ) ) {
        return true
      }else{
        return false
      }
    },
    formateExpireSeconds(){
      let t=this.$store.state.user_expire_seconds

      let h = parseInt(t / 60 / 60 % 24)
      let m = parseInt(t / 60 % 60)
      let s = parseInt(t % 60 )
      // 因为h已经是数字型了，如果0不加引号就变成加法了
      h = h < 10 ? '0' + h : h
      m = m < 10 ? '0' + m : m
      s = s < 10 ? '0' + s : s
      return `试用中：${m}分${s}秒`
    },
    closeDialogVideo(){
      this.playDialogVisibleVideo=false
    },
    openVideoDialog(){
    //  clickCourseTask(1)
      this.playDialogVisibleVideo=true
      storage.setUnSeeVideo(false)
      this.showDots=false
    }
  },
  filters:{
    userTagFilter(user_tag) {
      if (user_tag == "free") {
        return 'https://static.qiankun6.com/2022/0607/c0e9094a408a2b8d80d7d2a1d8e12899.png'
      } else if (user_tag == "eternal") {
        // return 'https://static.qiankun6.com/2022/0607/ef2e717440acd47e800677ed610753ec.png'
        return 'https://static.qiankun6.com/2022/0607/6354424340d1bcdf8071be86beea0bc8.png'
      } else if (user_tag == "supreme") {
        return 'https://static.qiankun6.com/2022/0105/Frame34@2x.png'
      } else {
        return 'https://static.qiankun6.com/2022/0607/6354424340d1bcdf8071be86beea0bc8.png'
      }

    }
  },
  mounted() {
    if(storage.getUnSeeVideo()!==''){
      this.showDots=storage.getUnSeeVideo();
      console.log("showDots11=",1)
    }else {
      this.showDots=true
      console.log("showDots11=",2)
    }
    console.log("showDots11=",this.showDots)
  }
}
</script>

<style lang="scss" scoped>
.mine_header_box {
  display: flex;
  justify-content: right;
  align-items: center;
  height: 60px;
  width: auto;

  .phone_box {
    display: flex;
    justify-content: right;
    width: auto;
    height: auto;
    text-align: left;
    align-items: center;

    .phone_text_mine {
      font-size: 14px;
      color: #333333;
      text-align: center;
      height: 16px;
      line-height: 16px;
    }

    .vip_img_style {
      width: 142px;
      height: 42px;
      margin: 0 auto;
      margin-left: 20px;
    }
  }
  .redDots {
    float: right;
    background: red;
    width: 5px;
    height: 5px;
    text-align: center;
    border-radius: 20px;
    font-size: 6px;
    margin-left: -10px;
    position: relative;
    margin-top: -5px;
    margin-right: 10px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center
  }
}
</style>
<style>
.customClass {
  border-radius: 12px;
}
</style>