  <template>
    <div>
      <div class="login_body_style">
        <div class="login_body_left">
          <el-image style="width: 800px; height: 533px" :src="url2"></el-image>
          <div style="display: flex" class="qrcodelay">
            <div style="display: flex;flex-direction:column">
              <el-image style="width: 150px; height: 150px" :src="urlios"></el-image>
              <a target="_black" href="https://apps.apple.com/cn/app/id6472891832">
                <el-image style="width: 150px; height: 52px;border-radius:10px;margin-top: 10px"
                          src="https://static.qiankun6.com/cloud/2023/39/iosbtn.png"></el-image>
              </a>
            </div>
            <div style="display: flex;flex-direction:column;margin-left: 20px">
              <el-image style="width: 150px; height: 150px" :src="urlandroid"></el-image>
              <a target="_black" href="https://www.pgyer.com/2pCgnd">
                <el-image style="width: 150px; height: 52px;border-radius:10px;margin-top: 10px"
                          src="https://static.qiankun6.com/cloud/2023/39/androidbtn.png"></el-image>
              </a>
            </div>
          </div>


        </div>
        <div class="login_body_right">
          <div>
            <el-image style="width: 200px; height: 59px;" :src="url"></el-image>
            <el-card v-if="showLogin" shadow="always" class="login-card">
              <el-tabs v-model="activeName" class="tabs">
                <el-tab-pane label="一键登录" name="first">
                  <div v-show="!showOnekeyTips">
                    <el-button type="primary" style="width: 100% ;margin-top: 20px"
                               @click="oneKeyLogin()"        
                               >
                      一键登录
                    </el-button>
                    <div class="login-tips">点击"一键登录"即表示您已同意并遵守 <a href="https://d2zvdta67a.feishu.cn/wiki/UGx2wj4nVighLtkL1XGcv3RDnYb?from=from_copylink"
                                                                              target="_blank"
                                                                              style="text-decoration: none"><span
                        style="color: #999999">《magPie服务条款》</span></a></div>
                  </div>
                  <div v-show="showOnekeyTips"> <!--一键登录提示页的展示 -->
                    <div style="font-size: 18px;color: #333333;margin-top: 8px;font-weight: bold;">初次登录成功</div>
                    <div style="font-size: 14px;color: #999999;margin-top: 25px;">登录秘钥:</div>
                    <div style="font-size: 14px;color: #999999;margin-top: 6px;">{{ showSK }}</div>
                    <el-button type="primary" style="width: 70% ;margin-top: 20px;border-radius: 20px;"  :disabled="isButtonDisabled" @click="copySKLoginin()">
                      复制并进入网站
                    </el-button>
                    <el-checkbox v-model="checked" class="round-checkbox" style="color: #333333;margin-top: 4px;">
                      请妥善保管登录秘钥，以便再次登录或遗忘
                    </el-checkbox>
                    <div style="font-size: 14px;color: #999999;margin-top: 50px;">
                      注意:收藏或妥善保管好该登录秘钥，你可在任意设备上选择秘钥登录即可完成登录,会同步漫游会员信息。
                      </div>
                  </div>
                </el-tab-pane>
                <el-tab-pane :label="isCodeLogin?'邮箱登录':'密码登录'" name="second">
                  <div>
                    <el-input prefix-icon="el-icon-message" v-model="phone"
                              placeholder="请输入邮箱" @keydown.enter.native="captchaLogin()"></el-input>
                    <el-input maxlength="6" v-if="isCodeLogin" prefix-icon="el-icon-key" style="margin-top: 5px"
                              placeholder="请输入验证码" v-model="loginCode" @keydown.enter.native="handleEnter">
                      <el-button v-if="!sendMsgDisabled" style="margin-top:4px" size="small" slot="suffix"
                                 :disabled="phone==''"
                                 type="primary" @click="sendLoginCode" :loading="loading">获取验证码
                      </el-button>
                      <el-button v-if="sendMsgDisabled" style="margin-top:4px" size="small" slot="suffix" disabled
                                 type="primary">{{ time + 's' }}
                      </el-button>
                    </el-input>
                    <el-button style="width: 100%;margin-top: 20px" :disabled="phone==''" type="primary" @click="captchaLogin()">立即登录
                    </el-button>
<!--                    <div style="display: flex;justify-content: space-between;margin-top: 20px">
                      <span class="login-pass-text"
                            @click="isCodeLogin=!isCodeLogin">{{ !isCodeLogin ? '短信快捷登录>>' : '密码登录>>' }}</span>
                      <span class="login-pass-text" @click="showLogin=false">忘记密码？</span>
                    </div>-->
                    <div class="login-tips">点击"立即登录"即表示您已同意并遵守 <a href="https://web.miao2021.cn/web/service_agreement.php"
                                                                  target="_blank"
                                                                  style="text-decoration: none"><span
                        style="color: #999999">《magPie服务条款》</span></a></div>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="秘钥登录" name="third">
                  <div>
                    <el-input prefix-icon="el-icon-key" v-model="secret_key"
                              placeholder="请输入秘钥" @keydown.enter.native="secretLogin()"></el-input>

                    <el-button type="primary" style="width: 100% ;margin-top: 20px" :disabled="secret_key==''"
                               @click="secretLogin()" >
                      立即登录
                    </el-button>
                    <div class="login-tips">点击"立即登录"即表示您已同意并遵守 <a href="https://d2zvdta67a.feishu.cn/wiki/UGx2wj4nVighLtkL1XGcv3RDnYb?from=from_copylink"
                                                                  target="_blank"
                                                                  style="text-decoration: none"><span
                        style="color: #999999">《magPie服务条款》</span></a></div>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </el-card>


          </div>

        </div>

      </div>

    </div>
  </template>

  <script>
  import {apiRequest} from '../api/api'
  import md5 from 'blueimp-md5'
  import getErrorcode from "../api/common.js"
  import {storage} from "@/utils/storage";
  import {isPhone, isPass, isCaptcha, isEmpty} from "@/utils/formatCheck";
  import LoginCaptcha from "@/components/LoginCaptcha";
  import getQueryString from "@/utils/getQuery";
  import { Message } from 'element-ui';
  import Fingerprint2 from 'fingerprintjs2'
  import ClipboardJS from 'clipboard'
  const { previewModeToken } = storage
  export default {
    name: "Login",
    components: {LoginCaptcha},
    data() {
      return {
        secret_key:"",
        showSK:"",
        getUid:"",
        getToken:"",
        showOnekeyTips: false, // 控制是否展示一键登录后提示页面
        checked: false,
        captchaType:'',
        showCaptcha:false,
        isCodeLogin:true,
        showLogin: true,
        website: 0,
        phone: '',
        loginCode: '',
        registerCode: '',
        resetCode: '',
        time: 60,
        sendMsgDisabled: false,
        password: '',
        passwordRegister: '',
        resetPassword: '',
        activeName: 'first',
        loading:false,
        url: "https://static.qiankun6.com/cloud/2024/124/20240124-155956.png",//https://static.qiankun6.com/cloud/2023/130/pclogo.png
        url2: "https://static.qiankun6.com/cloud/2023/29/pcmain1.png",
        urlandroid: "https://php.wzhz.xyz/api_fasd/qr_code?url=https://www.pgyer.com/2pCgnd&size=200",        urlios: "https://php.wzhz.xyz/api_fasd/qr_code?url=https://apps.apple.com/cn/app/id6472891832&size=200"
      }
    },
    computed: {
    isButtonDisabled() {
      return !this.checked;
    }
  },
    methods: {
      handleEnter(){
        console.log("点击回车")
        if(this.isCodeLogin){
          this.captchaLogin()
        }
      },
      ipToUUID(ip) {
        // 分割 IP 地址为四个部分
        const ipParts = ip.split('.').map(part => parseInt(part));
        
        // 确保 IP 地址有效
        if (ipParts.length !== 4 || ipParts.some(isNaN)) {
          return 'Invalid IP Address';
        }

        // 将 IP 地址的每个部分转换为16进制，并填充到 UUID 的特定位置
        // 这里仅作为示例，具体规则可以根据需要进行调整
        return [
          ipParts[0].toString(16).padStart(8, '0'),
          ipParts[1].toString(16).padStart(4, '0'),
          ipParts[2].toString(16).padStart(4, '0'),
          ipParts[3].toString(16).padStart(4, '0'),
          '000000000000'
        ].join('-').toUpperCase();
      },
      fingerprintToUUID(fingerprint) {
        if (fingerprint.length !== 32) {
            throw new Error('Invalid fingerprint length');
        }

          const parts = [
              fingerprint.substring(0, 8),
              fingerprint.substring(8, 12),
              fingerprint.substring(12, 16),
              fingerprint.substring(16, 20),
              fingerprint.substring(20)
          ];

        return parts.join('-').toUpperCase();
      },
      async  oneKeyLogin() {
        let currentIP = ""
        let fingerprint = ""
        Fingerprint2.get((components) => {
          fingerprint = Fingerprint2.x64hash128(components.map((pair) => pair.value).join(''), 31);
          if(fingerprint == null || fingerprint == ""){
            console.log("指纹id为空");

          }
          console.log("指纹id="+fingerprint);
          const  deviceId = this.fingerprintToUUID(fingerprint);
          console.log('设备ID:'+deviceId);
           var params = ({"request_type": "user/visitor_login","device_id": deviceId});
            apiRequest(params).then((res) => {
              // console.log('is_register='+res.is_register);
              // console.log('secret_key='+res.secret_key);
              // console.log('uid='+res.uid);
              // console.log('token='+res.token);
              this.showSK = res.secret_key;
              this.getToken = res.token;
              this.getUid = res.uid;

              //第一次一键登录
              if(res.is_register == true){
              //if(true){
                console.log('第一次一键登录');
                this.showOnekeyTips = true; // 点击按钮时切换展示内容
              }else{
                storage.setUid(res.uid);
                storage.setSK(res.secret_key);
                storage.setToken(res.token)
                this.$message.success('登录成功');
                this.$router.replace('/index')
              }
          });

        });
      },
      handleKeyPress(event) {//监听
        if (event.key === 'Enter') {
         //console.log('按下enter键 showOnekeyTips='+this.showOnekeyTips);
           // 判断当前选项卡的名称
           if(this.activeName == 'first'){
              if(!this.showOnekeyTips){
                //console.log('当前处于一键登录页面');
                this.oneKeyLogin();
              }else{
                //console.log('当前处于一键登录提示页面');
                if(this.checked){
                 // console.log('一键登录提示页面可点击');
                  this.copySKLoginin();

                }else{
                  //console.log('一键登录提示页面不可点击');
                }
              }
           }
        }
      },
      copySKLoginin(){
        const clipboard = new ClipboardJS('.btn', {
          text: () => this.showSK,
        });

        clipboard.on('success', (e) => {
          console.log('复制成功');
          // Message.success("秘钥复制成功");
          Message.success('登录成功,已复制秘钥');
          e.clearSelection();
          clipboard.destroy();
        });
      

        clipboard.on('error', (e) => {
          console.log('复制失败', e);
          Message.error("登录成功,秘钥复制失败,请手动复制秘钥");

          clipboard.destroy();
        });
        clipboard.onClick(event);

        console.log('复制秘钥并进入软件');
        storage.setUid(this.getUid);
        storage.setSK(this.showSK);
        storage.setToken(this.getToken)
       // this.$message.success('登录成功');
        this.$router.replace('/index')

      },
      sendResetCodePre(){
        if (!isPhone(this.phone.trim())) {
          this.$message.error('请输入正确的手机号');
          return
        }
        this.showCaptcha=true
        this.captchaType='reset'
      },
      sendLoginCode() {
        this.loading=true
        var params = ({"request_type": "sms/send_captcha_login", "email": this.phone});
        apiRequest(params).then((res) => {
          this.$message.success('验证码发送成功');
          this.loading=false
          this.startTime();
        });
      },

      captchaLogin() {
        if (!isCaptcha(this.loginCode)) {
          this.$message.error('验证码格式不正确');
          return
        }
          var params = ({"request_type": "user/captcha_login", "email": this.phone, "captcha":this.loginCode});
          apiRequest(params).then((res) => {
            storage.setUid(res.uid);
            storage.setSK(res.secret_key);
            storage.setToken(res.token)
            this.$message.success('登录成功');
            this.$router.replace('/index')

          });

      },

      secretLogin(){
        storage.setToken('')
        let params = ({
          "request_type": "user/secret_login", "secret_key": this.secret_key
        });
        apiRequest(params).then((res) => {

          storage.setUid(res.uid);
          storage.setSK(res.secret_key);
          storage.setToken(res.token)
          this.$message.success('登录成功');
          this.$router.replace('/index')
        });
      },
      startTime() {
        let me = this;
        me.sendMsgDisabled = true;
        let interval = setInterval(function () {
          if ((me.time--) <= 0) {
            me.time = 60;
            me.sendMsgDisabled = false;
            // me.canPress = true
            clearInterval(interval);
          }
        }, 1000);
      },
    },
    mounted() {
      window.addEventListener('keypress', this.handleKeyPress);

      this.website = getQueryString("website")
      console.log("website"+this.website);
      //this.urlandroid = "https://php.wzhz.xyz/api_fasd/qr_code?url=https://wdc2.info/gotostore.html?website=" + this.website + "%26wangdachui=0.04572124164920166&size=200",
     // this.urlios = "https://php.wzhz.xyz/api_fasd/qr_code?url=https://wdc2.info/gotostore.html?website=" + this.website + "%26wangdachui=0.20266935724343194&size=200"
    },
    // 在组件销毁时移除事件监听器
    beforeDestroy() {
      window.removeEventListener('keypress', this.handleKeyPress);
    },
  }
  </script>

  <style lang="scss" scoped>

  .login_body_style {
    display: flex;
    height: auto;
    position: fixed;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    background: #E9F3FE;

    .login_body_left {
      flex: 2;
      height: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      align-items: center;

    }

    .login_body_right {
      flex: 1;
      height: 100%;
      display: flex;
      justify-content: center;
      text-align: center;
      align-items: center;

      ::v-deep .round-checkbox .el-checkbox__inner {
        border-radius: 50%;
        /* 根据需要调整宽度和高度 */
      }

      .login-card {
        height: 370px;
        width: 380px;
        margin-top: 10px;
        justify-content: center;

        .tabs {
          justify-content: left;
        }

        .login-tips {
          font-size: 12px !important;
          margin-top: 35px;
          color: #999999;
        }

        .login-pass-text {
          color: #3079E7;
          font-size: 12px;
          cursor: pointer;
        }

        .back {
          color: #000000;
          font-size: 22px;
          cursor: pointer;
          float: left;
        }

        
      }
    }

    .qrcodelay {
      width: auto;
      height: auto;
      position: absolute;
      margin-top: -200px;
    }
  }
  </style>
