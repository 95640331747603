<template>
  <div id="app-ops">
    <el-container style="height: 100%">
      <LeftMenu :isCollapse="asideCollapse"></LeftMenu>
      <el-container>
        <el-header>
          <el-row>
<!--            <div class="collapse-btn"
                 @click="chgCollapse">
              <i class="el-icon-s-fold" style="height: 20px"></i>
            </div>-->
            <user-status></user-status>
          </el-row>
        </el-header>
        <el-main style="padding: 0" >
<!--          <transition name="slide-fade"
                      mode="out-in">
            <router-view />
          </transition>-->
          <router-view />
        </el-main>
      </el-container>
    </el-container>


  </div>
</template>

<script>
import LeftMenu from '@/components/LeftMenu.vue';
import {getUserStatus} from "@/utils/getUserStatus";
import userStatus from "@/components/user/UserStatus";

export default {
  data() {
    return {
      asideCollapse: false,
    };
  },
  methods: {
    chgCollapse() {
      this.asideCollapse = !this.asideCollapse;
    },
  },
  components: {
    LeftMenu,userStatus
  },
  computed: {

  },
   mounted() {
     getUserStatus();
  },
};
</script>



<style lang="less">

.collapse-btn {
  color: #333333;
  font-size: 26px;
  height: 60px;
  line-height: 60px;
  float: left;
  width: 60px;
  cursor: pointer;
}
#app-ops {
  //background-color: rgb(236, 236, 236);
  background: white;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.el-header {
  //background: #3c8dbc;
  background: #fff;
  color: #fff;
}
.el-breadcrumb {
  margin-bottom: 20px;
}
.el-select {
  width: 100%;
}
.el-pagination {
  margin-top: 16px;
}
.el-card {
  border: none;
}
.el-header {
  z-index: 999;
  box-shadow: 1px 4px 5px rgba(75, 75, 75, 0.1);
}
.el-menu-item.is-active {
/*  background: #1b3c4e !important;
  border-left: 2px solid #3c8dbc;*/
  background: #EEF5FF !important;
 // border-left: 2px solid #3c8dbc;
}
#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #000000;
    }
  }
}
.el-card__header {
  background-color: rgb(247, 247, 247);
  border-left: 3px solid #3c8dbc;
  & > div > span {
    font-weight: bolder;
  }
}
.el-menu--horizontal > .el-menu-item {
  color: #fff;
}
.el-card .el-card__header .el-button {
  float: right;
  padding: 2px 0;
}

.slide-fade-enter {
  opacity: 0;
  transform: translateX(-60px);
}
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.1s ease;
}
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
.main {
  position: relative;
}
.el-scrollbar__view {
  overflow-x: hidden;
  overflow-y: hidden;
}

.el-submenu .el-menu-item {
  height: 40px;
  line-height: 40px;
}
.el-input.is-disabled .el-input__inner {
  color: #777;
}
.el-textarea.is-disabled .el-textarea__inner {
  color: #777;
}
.action-btn-box {
  margin: -10px -10px 10px -10px;
  background: #fcfbd1;
  padding: 10px 0;
  position: fixed;
  z-index: 999;
  width: 500px;
  right: 50px;
}

.action-btn-box-standard {
  margin: -10px -10px 10px -10px;
  background: #f03434;
  padding: 5px;
  position: fixed;
  z-index: 999;
  width: 80px;
  right: 50px;
  color: #fff;
  text-align: center;
  cursor: pointer;
}
.action-btn-box-standard.edit{
  background: #2fa1ee;
}

td .cell textarea {
  border: none;
  cursor: pointer !important;
  background: rgba(0, 0, 0, 0) !important;
}
.a_tip{color: #777; height: 29px;line-height: 29px;font-size: 12px;}
.a_tip.center{
  text-align: center
}
</style>
