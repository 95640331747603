import {apiRequest} from '@/api/api'
import getErrorcode from "@/api/common.js"
import {storage} from "@/utils/storage";
import store from "@/store";
import { Message } from 'element-ui';
export function getUserStatus(){
    var params = ({"request_type": "user/get_user_status"});
    apiRequest(params).then((res) => {
        console.log(res)
        store.commit('updateUserStatus',res)
        /*if (res.code === "SUCCESS") {
            store.commit('updateUserStatus',res)
            for(let i=0;i<res.user_tags.length;i++){
                if(res.user_tags[i].probation===true){
                   // console.log("updateUserExpireSeconds",res.user_tags[i].expire_seconds)
                    store.commit('updateUserExpireSeconds',res.user_tags[i].expire_seconds)
                    return
                }
            }
        } else {
            Message.error(getErrorcode(res.code));
        }*/
    })

}
