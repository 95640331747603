<template>
  <div id="app">
    <router-view/>
    <el-dialog custom-class="customClass" :visible.sync="showUploadLinks" width="400px">
      <div style="font-size: 16px">检查到新的链接:magnet:?xt=urn:btih:{{btih}}</div>
      <div style="font-size: 16px">是否执行上传操作？</div>
      <span slot="footer" class="dialog-footer">
      <el-button round @click="showUploadLinks=false">取消</el-button>
      <el-button round type="primary" @click="upload()">上传</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { createWebSocket } from "@/utils/socket";
import Bus from "@/utils/bus";
import store from "@/store";
import ClipboardDialog from "@/components/ClipboardDialog";
import {isBtih, isHttp, getBtih} from "@/utils/formatCheck";
import {getUserStatus} from "@/utils/getUserStatus";
export default {
  components: {ClipboardDialog},
  data(){
    return{
      webJson:{},
      ts:0,
      showUploadLinks:false,
      links:"",
      btih:"",
    }
  },

  methods: {
    upload(){
      console.log("this.$route.path",this.$route.path)
      this.showUploadLinks=false
      if(this.$route.path==="/index"){
        Bus.$emit('uplinks', this.btih);
      }else {
        this.$router.push({name:'index',params: {btih:this.btih}})
      }


    },
    init() {
      createWebSocket(this.global_callback);
    },
    // websocket的回调函数，msg表示收到的消息
    global_callback(msg) {
     // console.log("websocket的回调函数收到服务器信息：" , msg);
      //console.log("websocket的回调函数收到服务器信息：" + JSON.stringify(msg));
      this.webJson=msg
      if(this.webJson.event==="clipboard"&&this.webJson.value!==""){
        if (isBtih(this.webJson.value)) {
          this.showUploadLinks=true
          this.links=this.webJson.value
          this.btih=getBtih(this.webJson.value)
        }
      }

    },
  },
  beforeDestroy() {
    Bus.$off('tryopen')
  },
  created() {
    this.init();
    Bus.$on('tryopen', (a)=>{
      if(a){
        this.init();
       // console.log("发送：",'未开启重试开启监听');
      }
    })


  },mounted() {
    let val=this
    let interval = setInterval(function () {

      if(store.state.user_expire_seconds!==0){
        //console.log("试用时间测试", store.state.user_expire_seconds--)
        store.state.user_expire_seconds--
        if(store.state.user_expire_seconds%10==0){
          getUserStatus();
        }
      }

      if (store.state.socket.status !== "open") {
        val.init();
       // console.log("定时器测试重连",'尝试连接')
      }
      else {
        //clearInterval(interval);
       // console.log("心跳",'已经链接')
      }
    }, 1000);
  }
}
</script>

<style>
html, body, #app {
  height: 100%;
}

body{
  margin: 0;
  padding: 0;
}
.customClass {
  border-radius: 12px;
}

</style>
